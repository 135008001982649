import React, { useRef, useState } from "react";
import { Input, notification, Form } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { sendEmailOtp, verifyOtp } from "../../store/slices/authSlice";
import OtpInput from "react-otp-input";

const Container = styled.div`
	padding: 0.5em;
	display: flex;
	flex-direction: column;
`;

const Message = styled.p`
	text-align: left;
	padding: 0.5em;
	font-weight: 600;
	font-size: 1em;
`;

const InputWrapper = styled.div`
	display: ${(props) => (props.isVisible ? "flex" : "none")};
	flex-direction: column;
`;

const StyledLabel = styled.label`
	width: 100%;
	padding: 0.5em;
	text-align: left;
	font-weight: 500;
`;

const StyledButton = styled.button`
	display: flex;
	width: 100%;
	padding: 0.5em 1em;
	border-radius: 4px;
	align-items: center;
	font-weight: 500;
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : "#6080f5"};
	text-align: center;
	cursor: pointer;
	height: 40px;
	border: none;
	color: #ffffff;
	:hover {
		background-color: white;
		color: black;
		border: 2px solid black;
		transition: 0.5s;
	}
`;

const OtpInputWrapper = styled.div`
	padding: 0.5em;
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const EmailAuth = (props) => {
	const otpButton = useRef();
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [otp, setOtp] = useState("");
	const [showOtp, setShowOtp] = useState(false);
	const { metaform } = useSelector((state) => state.reviewfyform);
	const { orgId } = metaform;

	const handleSendOtp = async () => {
		otpButton.current.style.backgroundColor = "grey";
		otpButton.current.style.cursor = "not-allowed";
		const response = await dispatch(sendEmailOtp({ email, orgId })).unwrap();
		if (response) {
			notification.success({ message: "OTP Sent Successfully" });
			setShowOtp(true);
			otpButton.current.innerHTML = "Verify OTP!";
			otpButton.current.disabled = true;
		}
	};

	const confirmOtp = async () => {
		const response = await dispatch(verifyOtp({ otp, email, orgId })).unwrap();
		if (response) {
			notification.success({ message: "Email Verified !" });
		}
	};

	return (
		<Container>
			<Message>
				{
					"Verify your email address to fill out this form! One-time password will be sent to your email "
				}
			</Message>
			<FormContainer>
				<Form onFinish={handleSendOtp}>
					<Form.Item
						name="email"
						rules={[
							{
								type: "email",
								message:
									"Invalid email format. Please enter a valid email address.",
							},
							{
								required: true,
								message: "Please enter your email address",
							},
						]}
					>
						<InputWrapper isVisible={true}>
							<StyledLabel>Enter your email address</StyledLabel>
							<Input
								type={"email"}
								value={email}
								onChange={({ target }) => setEmail(target.value)}
								required={true}
							/>
						</InputWrapper>
					</Form.Item>
					<Form.Item>
						<InputWrapper isVisible={showOtp}>
							<StyledLabel>
								Enter One-Time Password sent to your email address
							</StyledLabel>
							<OtpInputWrapper>
								<OtpInput
									value={otp}
									onChange={(otp) => {
										setOtp(otp);
									}}
									numInputs={6}
									separator={<span>-</span>}
									inputStyle={{
										width: "100%",
										height: "30px",
										borderRadius: "8px",
										border: "1px solid #d9d9d9",
									}}
								/>
							</OtpInputWrapper>
						</InputWrapper>
					</Form.Item>

					<StyledButton
						name="sendOtp"
						ref={otpButton}
						backgroundColor={"orange"}
						type={"submit"}
						style={{ display: `${otp.length !== 6 ? "block" : "none"}` }}
					>
						Send OTP
					</StyledButton>
				</Form>
				<StyledButton
					name="verifyOtp"
					backgroundColor={"orange"}
					style={{ display: `${otp.length === 6 ? "block" : "none"}` }}
					onClick={() => confirmOtp()}
				>
					Verfiy OTP
				</StyledButton>
			</FormContainer>
		</Container>
	);
};

export default EmailAuth;

import { combineReducers } from "redux";
import reviewfyformSlice from "./slices/reviewfySlice";
import analyticsSlice from "./slices/analyticsSlice";
import authSlice from "./slices/authSlice";

export default combineReducers({
	auth: authSlice,
	analytics: analyticsSlice,
	reviewfyform: reviewfyformSlice,
});

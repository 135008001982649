import React from "react";
import styled from "styled-components";
import { useState } from "react";

import LikeDefault from "../../assets/images/Like/LikeDefault.svg";
import LikeSelected from "../../assets/images/Like/Like.svg";
import DislikeDefault from "../../assets/images/Like/DisLikeDefault.svg";
import DislikeSelected from "../../assets/images/Like/DisLike.svg";

const Container = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
`;

const IconContainer = styled.span`
	padding: 1em;
	width: 80px;
	text-align: center;
	font-size: 1rem;
`;

const Wrapper = styled.span`
	padding: 1em;
	border-radius: 50%;
	cursor: pointer;
`;

const LikeDisLike = (props) => {
	const { isDisabled, handleChange, contentId } = props;
	const [liked, setLiked] = useState(null);

	return (
		<Container>
			<IconContainer>
				<Wrapper
					disabled={isDisabled}
					isLiked={liked === "dislike"}
					onClick={() => {
						setLiked("dislike");
						handleChange(contentId, 0);
					}}
				>
					<img
						src={liked === "dislike" ? DislikeSelected : DislikeDefault}
						alt={"broke"}
					></img>
				</Wrapper>
			</IconContainer>
			<IconContainer>
				<Wrapper
					isLiked={liked === "like"}
					onClick={() => {
						setLiked("like");
						handleChange(contentId, 1);
					}}
				>
					{/* <LikeOutlined
						disabled={isDisabled}
						style={{ color: `${liked === "like" ? "#1890ff" : "black"}` }}
					/> */}
					<img
						src={liked === "like" ? LikeSelected : LikeDefault}
						alt={"broke"}
					></img>
				</Wrapper>
			</IconContainer>
		</Container>
	);
};

export default LikeDisLike;

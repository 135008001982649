import "./App.css";
import "antd/dist/antd.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReviewfyForm from "./components/ReviewfyForm";
import Loading from "./components/Common/Loading";

function App() {
	return (
		<div className="App">
			<Loading />
			<BrowserRouter>
				<Routes>
					<Route
						exact
						path="/:orgId/reviewId/:shortId"
						element={<ReviewfyForm />}
					/>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;

import React, { Fragment } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import FormHeaderDesign from "../../assets/images/FormHeaderDesign.svg";

const Container = styled.div`
	position: absolute;
	top: -100px;
	border-radius: 0.4em;
	background-color: #ffffff;
	box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.18);
`;

const FormHeader = styled.div`
	height: 100px;
	width: 100%;
	border-bottom: solid 1px #dee5f4;
	margin-bottom: 1rem;
	background-image: url(${FormHeaderDesign});
	background-size: cover;
	display: flex;
	color: #ffffff;
	font-size: 30px;
`;

const HeaderIcon = styled.img`
	width: auto;
	height: 100%;
	display: ${(props) => (props.hasIcon ? "inline-block" : "none")};
`;

const FormTitle = styled.span`
	text-transform: capitalize;
	font-weight: 500;
	font-size: 24px;
	padding: 1em;
`;

const MessageContainer = styled.div`
	padding: 3em 1em;
`;

const SubmittedState = () => {
	const { metaform, customMessage } = useSelector(
		(state) => state.reviewfyform
	);

	console.log("customMessagecustomMessagecustomMessage", customMessage);

	return (
		<Container>
			{metaform && Object.keys(metaform).length ? (
				<Fragment>
					<FormHeader>
						<HeaderIcon
							src={metaform && metaform["formHeader"]["formIcon"]}
							hasIcon={
								metaform && metaform["formHeader"]["formIcon"] ? true : false
							}
						></HeaderIcon>
						<FormTitle>{metaform["formName"]}</FormTitle>
					</FormHeader>
					<MessageContainer>
						<h4>{customMessage}</h4>
					</MessageContainer>
				</Fragment>
			) : (
				""
			)}
		</Container>
	);
};

export default SubmittedState;

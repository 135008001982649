import React, { useState } from "react";
import styled from "styled-components";
import Rate from "../../assets/images/Rate/Rate.svg";
import RateDefault from "../../assets/images/Rate/RateDefault.svg";

const RaterWrapper = styled.div`
	height: 30px;
	width: 100%;
	display: flex;
	align-items: center;
`;

const StarRating = (props) => {
	const { handleChange, contentId } = props;
	// const [rateValue, setRateValue] = useState(defaultRate);

	const [responseSet, setResponseSet] = useState([
		{
			_id: 0,
			state: "default",
			default: RateDefault,
			selected: Rate,
		},
		{
			_id: 1,
			state: "default",
			default: RateDefault,
			selected: Rate,
		},
		{
			_id: 2,
			state: "default",
			default: RateDefault,
			selected: Rate,
		},
		{
			_id: 3,
			state: "default",
			default: RateDefault,
			selected: Rate,
		},
		{
			_id: 4,
			state: "default",
			default: RateDefault,
			selected: Rate,
		},
	]);
	const [hovered, setHovered] = useState({});

	const handleRating = (item, index) => {
		console.log(item, index);
		let rateSet = [...responseSet];
		let updatedRate = rateSet.map((rateItem, _index) => {
			return {
				...rateItem,
				state: `${_index <= index ? "selected" : "default"}`,
			};
		});
		console.log(updatedRate);
		setResponseSet([...updatedRate]);
		handleChange(contentId, index + 1);
	};

	return (
		<RaterWrapper>
			{responseSet.map((item, index) => {
				return (
					<img
						key={index}
						src={
							item["_id"] === hovered["_id"] || item["state"] === "selected"
								? item.selected
								: item.default
						}
						height={"100%"}
						style={{ margin: "0px 1em", cursor: "pointer" }}
						alt="broken-smiley"
						onMouseEnter={() => {
							setHovered(item);
						}}
						onClick={() => handleRating(item, index)}
						onMouseOut={() => setHovered({})}
					></img>
				);
			})}
		</RaterWrapper>
	);
};

export default StarRating;

import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import NavBar from "./FormComponents/NavBar";
import FormBox from "./FormComponents/FormBox";
import SubmittedState from "./FormComponents/Submitted";
import AuthOverLay from "./FormAuthentication/OverLay";
import ReviewfyDoodle from "../assets/images/ReviewfyEndUserDoodle.svg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getShortIdData } from "../store/slices/reviewfySlice";

const Container = styled.div`
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const FormContainer = styled.div`
	width: 100%;
	max-height: 75vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	padding: 1em;
	// background-image: url(${ReviewfyDoodle});
`;

const BodyContainer = styled.div`
	width: 100%;
	height: 80vh;
	position: relative;
	background-color: #e1e4f2;
`;

const ReviewfyForm = () => {
	const dispatch = useDispatch();
	const { orgId, shortId } = useParams();

	const fetchShortIdData = useCallback(
		() => dispatch(getShortIdData({ orgId, shortId })),
		[dispatch, orgId, shortId]
	);

	useEffect(() => {
		fetchShortIdData();
	}, [fetchShortIdData]);

	const { metaform, isSubmitted } = useSelector((state) => state.reviewfyform);

	return (
		<Container>
			<AuthOverLay
				isAuthRequired={metaform && !metaform.emailVerified & !isSubmitted}
			/>
			<NavBar></NavBar>
			<BodyContainer>
				{isSubmitted ? (
					<FormContainer>
						<SubmittedState />
					</FormContainer>
				) : (
					<FormContainer>
						<FormBox />
					</FormContainer>
				)}
			</BodyContainer>
		</Container>
	);
};

export default ReviewfyForm;

import React from "react";
import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { Slider } from "antd";
import { useState } from "react";

const SliderResponse = (props) => {
	const { isDisabled, handleChange, contentId } = props;
	const ratingLevel = { min: 1, max: 5 };
	const { min, max } = ratingLevel;
	const [value, setValue] = useState(0);
	const mid = Number(((max - min) / 2).toFixed(5)) + 1;
	const preColorCls = value >= mid ? "" : "icon-wrapper-active";
	const nextColorCls = value >= mid ? "icon-wrapper-active" : "";

	const getTipFormatter = (value) => {
		const toolTipMessage = [
			"",
			"Terrible",
			"Not Satisfied",
			"Okayish",
			"Good",
			"Fantastic",
		];
		return toolTipMessage[value];
	};

	return (
		<div className="icon-wrapper">
			<FrownOutlined className={preColorCls} />
			<Slider
				disabled={isDisabled}
				onChange={(value) => {
					if (!isDisabled) {
						setValue(value);
						handleChange(contentId, value);
					}
				}}
				dots={true}
				min={1}
				max={5}
				value={value}
				tipFormatter={getTipFormatter}
			/>
			<SmileOutlined className={nextColorCls} />
		</div>
	);
};

export default SliderResponse;

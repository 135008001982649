import React, { Fragment, useState } from "react";
import styled from "styled-components";
import EmailAuth from "./EmailAuth";
import VerifyEmail from "../../assets/images/VerifyEmail.svg";

const OverLay = styled.div`
	background: rgba(0, 0, 0, 0.75);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0px;
	right: 0px;
	width: 100%;
	z-index: 1;
	display: ${(props) => (props.isAuthRequired ? "flex" : "none")};
	justify-content: center;
	align-items: center;
`;

const AuthBox = styled.div`
	width: 25%;
	border-radius: 1em;
	padding: 1em;
	background-color: #ffffff;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	@media screen and (max-width: 1200px) and (min-width: 801px) {
		height: auto;
		width: 50%;
		box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.18);
		background-color: #ffffff;
	}
	@media screen and (max-width: 800px) {
		height: auto;
		width: 75%;
		box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.18);
		background-color: #ffffff;
	}
`;

const AuthIcon = styled.img`
	padding: 1em;
`;

const ButtonContainer = styled.div`
	width: 100%;
`;

const StyledButton = styled.div`
	display: flex;
	width: auto;
	padding: 0.5em 1em;
	border-radius: 4px;
	align-items: center;
	font-weight: 500;
	background: #6080f5;
	text-align: center;
	cursor: pointer;
	height: 40px;
	margin-top: 1em;
	color: #ffffff;
	:hover {
		background-color: white;
		color: black;
		border: 2px solid black;
		transition: 0.5s;
	}
`;

const AuthOverLay = (props) => {
	const [selectedAuth, setSelectedAuth] = useState("");
	const { isAuthRequired } = props;

	const handleAuthSelection = (authMechanism) => {
		setSelectedAuth(authMechanism);
	};

	return (
		<OverLay isAuthRequired={isAuthRequired}>
			<AuthBox>
				{selectedAuth === "discord" || selectedAuth === "email" ? (
					<EmailAuth setSelectedAuth={setSelectedAuth}></EmailAuth>
				) : (
					<Fragment>
						<AuthIcon src={VerifyEmail} alt="brokenIcon" />
						<ButtonContainer>
							{/* <StyledButton onClick={() => handleAuthSelection("discord")}>
								{"Sign In with Discord"}
							</StyledButton> */}
							<StyledButton onClick={() => handleAuthSelection("email")}>
								{"Sign In with Email"}
							</StyledButton>
						</ButtonContainer>
					</Fragment>
				)}
			</AuthBox>
		</OverLay>
	);
};

export default AuthOverLay;

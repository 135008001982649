import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ReviewfyCoin from "../../assets/images/ReviewfyCoin.png";

const Container = styled.div`
	background: rgba(255, 255, 255, 0.8);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 5;
	display: ${(props) => (props.visible ? "flex" : "none")};
	justify-content: center;
	align-items: center;
`;

const IconWrapper = styled.div`
	width: 50%;
	height: 50%;
	margin: 0px auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// background: yellow;
`;

const Icon = styled.img`
	width: 100px;
	height: 100px;
	// animation: spin 1s linear infinite;
	// background: red;
	padding: 1rem;
	animation: zoom-in-zoom-out 1s infinite;
	@keyframes zoom-in-zoom-out {
		0% {
			transform: scale(1, 1);
		}
		50% {
			transform: scale(1.5, 1.5);
		}
		100% {
			transform: scale(1, 1);
		}
	}
`;

const Loading = (props) => {
	const { isLoading } = useSelector((state) => state.reviewfyform);

	return (
		<Container visible={isLoading}>
			<IconWrapper>
				<Icon src={ReviewfyCoin} />
			</IconWrapper>
		</Container>
	);
};

export default Loading;

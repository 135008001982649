import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Input, notification } from "antd";
import { Emoji, StarRating } from "../FormResponseTypes/index";
import { useDispatch } from "react-redux";
import {
	submitReview,
	setSubmittedState,
} from "../../store/slices/reviewfySlice";
import SliderResponse from "../FormResponseTypes/Slider";
import LikeDisLike from "../FormResponseTypes/LikeDislike";
import FormHeaderDesign from "../../assets/images/FormHeaderDesign.svg";
import parse from "html-react-parser";

const Container = styled.div`
	width: 40%;
	border-radius: 8px;
	box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.18);
	background-color: #ffffff;
	padding-bottom: 1rem;
	position: absolute;
	top: -100px;
	display: ${(props) => (!props.isVisible ? "block" : "none")};
	@media screen and (max-width: 1200px) and (min-width: 801px) {
		width: 80%;
		box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.18);
		background-color: #ffffff;
	}
	@media screen and (max-width: 800px) {
		width: 100%;
		box-shadow: 0 3px 17px 0 rgba(64, 142, 255, 0.18);
		background-color: #ffffff;
	}
`;

const FormHeader = styled.div`
	height: 100px;
	width: 100%;
	border-bottom: solid 1px #dee5f4;
	background-image: url(${FormHeaderDesign});
	background-size: cover;
	color: white;
	text-transform: capitalize;
	display: flex;
	align-items: center;
`;

const HeaderIcon = styled.img`
	width: auto;
	height: 80%;
	display: ${(props) => (props.hasIcon ? "inline-block" : "none")};
	padding: 0px 1em;
	margin-left: 1em;
`;

const FormTitle = styled.span`
	font-weight: 400;
	font-size: 24px;
	line-height: 24px;
	color: #ffffff;
	text-align: left;
`;

const FormItemContainer = styled.div`
	padding: 1em;
`;

const Footer = styled.span`
	height: 20px;
	width: 100%;
	display: flex;
	padding: 0.5em;
	justify-content: center;
	font-size: 0.6em;
	font-weight: 400;
`;

const ContentContainer = styled.div`
	width: 100%;
	height: 90px;
	margin-bottom: 0.3em;
`;

const QueryNumber = styled.span`
	height: 100%;
	padding: 1em;
	font-weight: 500;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const ContentWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: ${(props) =>
		props.type === "text" ? "flex-start" : "space-between"};
`;

const TextQueryContainer = styled.div`
	font-size: 12px;
	font-weight: 700;
	text-transform: capitalize;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
`;

const ResponseTypeContainer = styled.div`
	width: 100%;
`;

const IconLabelContainer = styled.div`
	height: 100%;
`;

const IconLabelWrapper = styled.div`
	font-weight: 700;
	font-size: 12px;
	line-height: 21px;
	text-transform: capitalize;
	padding: 5px;
	text-align: left;
`;
const IconContainer = styled.img`
	width: 80px;
	height: 80px;
	padding: 0.5em;
	object-fit: contain;
	margin-right: 1em;
`;

const ButtonContainer = styled.div`
	padding: 1em;
	display: flex;
	justify-content: center;
`;

const SubmitButton = styled.button`
	background-color: #6080f5;
	color: white;
	border: none;
	width: 100%;
	padding: 0.5em 0px;
	color: white;
	font-weight: 600;
	box-shadow: 0px 5px 10px rgba(96, 128, 245, 0.5);
	border-radius: 8px;
	cursor: ${(props) => (props.disabled ? "disabled" : "pointer")};
`;

const FormMessage = styled.div`
	text-align: left;
	padding: 0.5em 0px;
`;

const FormBox = () => {
	const dispatch = useDispatch();
	const [formResponses, setFormResponses] = useState({});
	const [disableButton, setDisableButton] = useState(false);
	const { metaform } = useSelector((state) => state.reviewfyform);
	const { walletAddress } = useSelector((state) => state.auth);

	const handleRatingChange = (contentId, rating) => {
		console.log({ ...formResponses, [contentId]: rating });
		setFormResponses({ ...formResponses, [contentId]: rating });
	};

	const renderResponseType = (item, disableState) => {
		const { responseType, _id } = item;
		switch (responseType) {
			case "text":
				return (
					<Input
						isDisabled={disableState}
						onChange={(e) => {
							handleRatingChange(_id, e.target.value);
						}}
					></Input>
				);

			case "rate":
				return (
					<StarRating
						contentId={_id}
						handleChange={handleRatingChange}
					></StarRating>
				);

			case "emoji":
				return (
					<Emoji
						isDisabled={disableState}
						contentId={_id}
						handleChange={handleRatingChange}
						onChange={(e) => {
							setFormResponses({ ...formResponses, [_id]: e.target.value });
						}}
					></Emoji>
				);

			case "slider":
				return (
					<SliderResponse
						isDisabled={disableState}
						contentId={_id}
						handleChange={handleRatingChange}
						onChange={(e) => {
							setFormResponses({ ...formResponses, [_id]: e.target.value });
						}}
					></SliderResponse>
				);

			case "like":
				return (
					<LikeDisLike
						isDisabled={disableState}
						contentId={_id}
						handleChange={handleRatingChange}
						onChange={(e) => {
							setFormResponses({ ...formResponses, [_id]: e.target.value });
						}}
					></LikeDisLike>
				);

			default:
				return;
		}
	};

	const onFinish = async (e) => {
		e.preventDefault();
		let reviewfyForm = [...metaform["feedbackForm"]];

		// Validate if all the questions are answered!
		if (Object.keys(formResponses).length !== metaform["feedbackForm"].length) {
			notification["error"]({
				message: "Review Incomplete",
				description: "Please fill in all the fields and submit",
			});
			return null;
		}
		// eslint-disable-next-line array-callback-return
		Object.keys(formResponses).some((resultKey) => {
			if (resultKey && formResponses.hasOwnProperty(resultKey)) {
				const index = reviewfyForm.findIndex((item) => item._id === resultKey);
				let response = {
					...reviewfyForm[index],
					responseValue: formResponses[resultKey],
				};
				reviewfyForm.splice(index, 1, response);
			}
		});

		let reviewfyFeedback = {
			...metaform,
			feedbackForm: [...reviewfyForm],
			shortId: window.location.pathname.split("/").pop(),
			walletAddress: walletAddress,
		};

		setDisableButton(true);
		const response = await dispatch(submitReview(reviewfyFeedback)).unwrap();
		if (response.code === "FORM_SUBMIT_SUCCESS") {
			dispatch(setSubmittedState({ submittedState: true }));
		}
	};

	return (
		<Container>
			{metaform && Object.keys(metaform).length > 0 ? (
				<Fragment>
					<form onSubmit={onFinish}>
						<FormHeader>
							<HeaderIcon
								src={metaform["formHeader"]["formIcon"]}
								hasIcon={metaform["formHeader"]["formIcon"] ? true : false}
							></HeaderIcon>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "left",
								}}
							>
								<FormTitle
									style={{
										marginLeft: metaform["formHeader"]["formIcon"]
											? "0em"
											: "2em",
									}}
								>
									{metaform["formName"]}
								</FormTitle>
								<FormMessage
									style={{
										marginLeft: metaform["formHeader"]["formIcon"]
											? "0em"
											: "2em",
									}}
								>
									{metaform["formHeader"]["message"]}
								</FormMessage>
							</div>
						</FormHeader>

						<FormItemContainer>
							{metaform["feedbackForm"].map((content, index) => (
								<ContentContainer key={index} index={index}>
									{content.queryType === "text" ? (
										<ContentWrapper type={"text"}>
											<QueryNumber>{`${index + 1}. `}</QueryNumber>
											<TextQueryContainer>
												<span
													style={{
														padding: "5px",
													}}
												>{`${parse(content.queryValue)}`}</span>
												<ResponseTypeContainer>
													{renderResponseType(content, false)}
												</ResponseTypeContainer>
											</TextQueryContainer>
										</ContentWrapper>
									) : (
										<ContentWrapper>
											<div style={{ display: "flex" }}>
												<QueryNumber>{`${index + 1}. `}</QueryNumber>
												<IconContainer src={content.queryValue}></IconContainer>
												<IconLabelContainer>
													<IconLabelWrapper>{`${content.iconLabel}`}</IconLabelWrapper>
													<ResponseTypeContainer>
														{renderResponseType(content, false)}
													</ResponseTypeContainer>
												</IconLabelContainer>
											</div>
										</ContentWrapper>
									)}
								</ContentContainer>
							))}
							<ButtonContainer>
								<SubmitButton htmlType="submit" disabled={disableButton}>
									Submit Your Response
								</SubmitButton>
							</ButtonContainer>
						</FormItemContainer>
					</form>
				</Fragment>
			) : (
				""
			)}
			<Footer>{" Powered ⚡ by reviewfy.io"}</Footer>
		</Container>
	);
};

export default FormBox;

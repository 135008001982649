import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

let apiEndPoint = null;

if (window.location.origin === "http://localhost:3005") {
	apiEndPoint = "http://localhost:3001";
} else {
	apiEndPoint = "https://console.reviewfy.io";
}

const initialState = {
	metaform: {},
	isSubmitted: false,
	isLoading: false,
	customMessage: "",
};

export const getShortIdData = createAsyncThunk(
	"forms/get-short-id",
	async (payload = {}, ThunkAPI) => {
		ThunkAPI.dispatch(setLoadingState({ loading: true }));
		const { orgId, shortId } = payload;
		const response = await axios.get(
			`${apiEndPoint}/api/review/shorturl?org=${orgId}&_id=${shortId}`
		);
		ThunkAPI.dispatch(setLoadingState({ loading: false }));
		return response.data;
	}
);

export const submitReview = createAsyncThunk(
	"forms/submitReview",
	async (payload, ThunkAPI) => {
		ThunkAPI.dispatch(setLoadingState({ loading: true }));
		const response = await axios.post(
			`${apiEndPoint}/api/review/reviewResponse`,
			payload
		);
		ThunkAPI.dispatch(setLoadingState({ loading: false }));
		return response.data;
	}
);

const formSlice = createSlice({
	name: "reviewfy-form",
	initialState,
	reducers: {
		setLoadingState: (state, action) => {
			state.isLoading = action.payload.loading;
		},
		setSubmittedState: (state, action) => {
			const { submittedState } = action.payload;
			state.isSubmitted = submittedState;
			state.isLoading = false;
		},
		updateFormMetadata: (state, action) => {
			const metaForm = action.payload;
			state.metaform = { ...metaForm };
		},
	},
	extraReducers: {
		[getShortIdData.fulfilled]: (state, action) => {
			if (
				action.payload.hasOwnProperty("code") &&
				action.payload.code === "FORM_ALREADY_SUBMITTED"
			) {
				const { payload: formInfo, message } = action.payload;
				state.isSubmitted = true;
				state.metaform = formInfo;
				state.customMessage = message;
			} else if (
				action.payload.hasOwnProperty("code") &&
				action.payload.code === "FORM_INACTIVE"
			) {
				const { payload: formInfo, message } = action.payload;
				state.isSubmitted = true;
				state.metaform = formInfo;
				state.customMessage = message;
			} else {
				const {
					encodedString: response,
					publicShortCode,
					expired,
					tokenValue,
				} = action.payload;
				const encodedString = JSON.parse(atob(response));
				const {
					email,
					formId,
					formName,
					formHeader,
					feedbackForm,
					channel,
					orgId,
					workspaceId,
				} = encodedString;

				const metaForm = {
					orgId,
					formId,
					formName,
					formHeader,
					feedbackForm,
					channel,
					publicShortCode,
					expired,
					email,
					workspaceId,
					tokenValue,
				};

				if (channel === "whatsapp") {
					metaForm["email"] = {
						id: encodedString["whatsapp"]["id"],
						subject: encodedString["whatsapp"]["subject"],
					};
				}
				metaForm["emailVerified"] = publicShortCode ? false : true;

				state.metaform = { ...metaForm };
			}
		},
		[submitReview.fulfilled]: (state, action) => {
			if (
				action.payload.hasOwnProperty("code") &&
				action.payload.code === "FORM_SUBMIT_SUCCESS"
			) {
				const { payload: formInfo } = action.payload;
				state.isSubmitted = true;
				state.metaform = formInfo;
				state.customMessage = "Your response has been recorded successfully!";
			}
		},
	},
});

export const { setSubmittedState, setLoadingState, updateFormMetadata } =
	formSlice.actions;
export default formSlice.reducer;

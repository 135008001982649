import React, { useState } from "react";
import styled from "styled-components";
import EmojiPoor from "../../assets/images/Emoji/EmojiPoor.svg";
import EmojiDefaultPoor from "../../assets/images/Emoji/EmojiDefaultPoor.svg";
import EmojiBad from "../../assets/images/Emoji/EmojiBad.svg";
import EmojiDefaultBad from "../../assets/images/Emoji/EmojiDefaultBad.svg";
import EmojiFair from "../../assets/images/Emoji/EmojiFair.svg";
import EmojiDefaultFair from "../../assets/images/Emoji/EmojiDefaultFair.svg";
import EmojiVeryGood from "../../assets/images/Emoji/EmojiVeryGood.svg";
import EmojiDefaultVeryGood from "../../assets/images/Emoji/EmojiDefaultVeryGood.svg";
import EmojiExcellent from "../../assets/images/Emoji/EmojiExcellent.svg";
import EmojiDefaultExcellent from "../../assets/images/Emoji/EmojiDefaultExcellent.svg";

const EmojiSetWrapper = styled.div`
	height: 30px;
	width: 100%;
	display: flex;
	align-items: center;
`;

const Emoji = (props) => {
	const { handleChange, contentId } = props;

	const emojiSet = [
		{
			_id: 0,
			state: "default",
			default: EmojiDefaultPoor,
			selected: EmojiPoor,
		},
		{
			_id: 1,
			state: "default",
			default: EmojiDefaultBad,
			selected: EmojiBad,
		},
		{
			_id: 2,
			state: "default",
			default: EmojiDefaultFair,
			selected: EmojiFair,
		},
		{
			_id: 3,
			state: "default",
			default: EmojiDefaultVeryGood,
			selected: EmojiVeryGood,
		},
		{
			_id: 4,
			state: "default",
			default: EmojiDefaultExcellent,
			selected: EmojiExcellent,
		},
	];

	const [responseSet, setResponseSet] = useState([...emojiSet]);
	const [hovered, setHovered] = useState({});

	return (
		<EmojiSetWrapper>
			{responseSet.map((item, index) => {
				return (
					<img
						key={index}
						src={
							item["_id"] === hovered["_id"] || item["state"] === "selected"
								? item.selected
								: item.default
						}
						height={"100%"}
						style={{ margin: "0px 1em", cursor: "pointer" }}
						alt="broken-smiley"
						onMouseEnter={() => {
							setHovered(item);
						}}
						onClick={() => {
							let emojiSet = [...responseSet];
							emojiSet = emojiSet.map((item) => {
								return { ...item, state: "default" };
							});

							emojiSet[index].state =
								emojiSet[index].state === "selected" ? "default" : "selected";
							handleChange(contentId, index + 1);
							setResponseSet([...emojiSet]);
						}}
						onMouseOut={() => setHovered({})}
					></img>
				);
			})}
		</EmojiSetWrapper>
	);
};

export default Emoji;

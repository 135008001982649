import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const analyticsSlice = createSlice({
	name: "formSlice",
	initialState,
	reducers: {},
	extraReducers: {},
});

export default analyticsSlice.reducer;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import styled from "styled-components";
import MetaMask from "../../assets/images/Metamask";
import { contractConfig } from "../../contracts/contracts";
import { useSelector } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert, Input, message, Modal, Tag, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { setWalletAddress } from "../../store/slices/authSlice";
import { useState } from "react";
import web3 from "web3";

const NavBarContainer = styled.div`
	width: 100%;
	height: 20vh;
	padding: 1em;
	display: flex;
	justify-content: flex-end;
	border-bottom: solid 1px #dee5f4;
	@media screen and (max-width: 400px) {
		width: 100%;
		// flex-direction: column;
		// height: auto;
	}
`;

const StyledButton = styled.div`
	align-items: center;
	max-width: 350px;
	border-radius: 4px;
	font-weight: 500;
	background-color: #6080f5;
	cursor: pointer;
	height: 40px;
	color: white;
	padding: 0.5em;
`;

const NavBar = () => {
	const dispatch = useDispatch();
	const [walletModal, showWalletModal] = useState(false);
	const [userWallet, setUserWallet] = useState("");
	const { isSubmitted, metaform } = useSelector((state) => state.reviewfyform);
	const { walletAddress } = useSelector((state) => state.auth);
	const { tokenValue } = metaform;

	const getWalletAddressManually = async () => {
		if (userWallet.length) {
			if (web3.utils.isAddress(userWallet)) {
				dispatch(setWalletAddress({ walletAddress: userWallet }));
				showWalletModal(false);
			} else {
				message.error("Not a valid wallet address.");
			}
		} else {
			message.error("Please enter valid wallet address.");
		}
	};

	const renderAlert = () => {
		if (!walletAddress) {
			return (
				<Alert
					message={
						<span>
							{
								"On Submission of this Form, you are eligible to receive $R5 tokens, Connect your wallet to receive R5 ERC-20 tokens or enter your wallet address manually"
							}
							<span
								style={{ cursor: "pointer" }}
								onClick={() => {
									showWalletModal(true);
								}}
							>
								<a href={"#"}>{" here "}</a>
							</span>
							!!
						</span>
					}
					style={{
						width: "100%",
						display: `${walletAddress || isSubmitted || !tokenValue ? "none" : "flex"
							}`,
					}}
					closable
				></Alert>
			);
		}
	};

	const addTokenToMetaMask = async () => {
		const ethereum = window.ethereum;
		if (ethereum) {
			// await window.ethereum.request({
			// 	method: "wallet_switchEthereumChain",
			// 	params: [{ chainId: "0x13881" }], // chainId must be in hexadecimal numbers
			// });

			const userWallet = await window.ethereum.request({
				method: "eth_requestAccounts",
			});

			if (userWallet.length) {
				dispatch(setWalletAddress({ walletAddress: userWallet[0] }));
			}

			const { contractAddress, symbol, decimals } = contractConfig;
			await ethereum.request({
				method: "wallet_watchAsset",
				params: {
					type: "ERC20", // Initially only supports ERC20, but eventually more!
					options: {
						address: contractAddress, // The address that the token is at.
						symbol: symbol, // A ticker symbol or shorthand, up to 5 chars.
						decimals: decimals, // The number of decimals in the token
					},
				},
			});
		} else {
		}
	};

	return (
		<>
			{renderAlert()}
			<NavBarContainer>
				<StyledButton
					onClick={addTokenToMetaMask}
					style={{
						display: `${walletAddress || isSubmitted || !tokenValue ? "none" : "flex"
							}`,
					}}
				>
					<span> {"Add $R5 to "}</span>
					<MetaMask style={{ height: "100%" }}></MetaMask>
					<Tooltip
						placement="bottom"
						title="Connect your wallet to receive Reviewfy (R5) tokens."
					>
						<InfoCircleOutlined />
					</Tooltip>
				</StyledButton>
				<span style={{ display: `${walletAddress ? "block" : "none"}` }}>
					<p style={{ fontWeight: 500 }}>
						{`Verified Public Wallet Address: `}
						<Tag color={"#6080f5"}>{walletAddress}</Tag>
					</p>
				</span>
				<>
					<Modal
						closable
						onCancel={() => showWalletModal(false)}
						onOk={() => {
							getWalletAddressManually();
						}}
						title={
							<div style={{ display: "flex" }}>
								<MetaMask style={{ height: "35px" }}></MetaMask>
							</div>
						}
						visible={walletModal}
					>
						<h4>Your metamask wallet Address</h4>
						<Input
							value={userWallet}
							onChange={({ target }) => {
								setUserWallet(target.value);
							}}
						></Input>
					</Modal>
				</>
			</NavBarContainer>
		</>
	);
};

export default NavBar;
